export type TCardTag = {
  color: string;
  title: string;
  icon: string;
};
export type TCard = {
  svg?: string;
  image?: string;
  background: string;
  type: string;
  title: string;
  description: string;
  transaction_fee: string;
  deposit_fee: string;
  withdraw_fee: string;
  monthly_payment: string;
  old_monthly_payment: string;
  cost_issue: string;
  reorder_payment?: string;
  qty_bins: string;
  get_card_link?: string;
  get_card_title?: string;
  learn_more_link: string;
  num_free_cards?: string;
  hidden: boolean;
  tags?: TCardTag[];
  cashback?: string;
};
export type TLandingCard = {
  slug: string;
  title: string;
  background: string;
  transaction_fee: string;
  deposit_fee: string;
  monthly_payment: string;
  [key: string]: any;
};

export const cards: TCard[] = [
  {
    svg: "private",
    image: "",
    background: "dark",
    type: "private",
    title: "PST Private",
    description: "cards.privateDesc",
    transaction_fee: "0 $",
    deposit_fee: "3%",
    cashback: "3%",
    monthly_payment: "1$",
    old_monthly_payment: "",
    qty_bins: "22",
    get_card_link: "/",
    get_card_title: "btn.getNumberFreeCards",
    num_free_cards: "100",
    learn_more_link: "/private",
    hidden: false,
    tags: [
      {
        color: "grey",
        title: "cards.tagForAdvertisements",
        icon: "diamond-simple",
      },
      // {
      //   color: "orange",
      //   title: "cards.tagForAffiliateTeams",
      //   icon: "diamond-simple",
      // },
      {
        color: "orange",
        title: "cards.tagQtyBins",
        icon: "flash",
      },
      {
        color: "orange",
        title: "cards.tagExpressWithdrawal",
        icon: "clock",
      },
      {
        color: "orange",
        title: "cards.tagFreeApiAccess",
        icon: "api",
      },
      {
        color: "orange",
        title: "cards.tagPrivateManager",
        icon: "user",
      },
    ],
  },

  {
    svg: "ultima",
    image: "",
    background: "light",
    type: "ultima",
    title: "Ultima",
    description: "cards.ultimaDesc",
    transaction_fee: "",
    deposit_fee: "",
    withdraw_fee: "0%",
    monthly_payment: "",
    old_monthly_payment: "",
    reorder_payment: "",
    cost_issue: "7$",
    qty_bins: "",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/ultima",
    hidden: false,
    tags: [
      {
        color: "grey",
        title: "cards.tagForAllPurchases",
        icon: "purchase",
      },
      {
        color: "orange",
        title: "cards.tagTheBestOfOurCards",
        icon: "star",
      },
    ],
  },

  {
    svg: "fb",
    image: "",
    background: "light",
    type: "fb",
    title: "Facebook",
    description: "cards.fbDesc",
    transaction_fee: "0,5 $",
    deposit_fee: "2,9%",
    monthly_payment: "10 $",
    old_monthly_payment: "",
    qty_bins: "17",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/facebook",
    hidden: false,
    tags: [
      {
        color: "grey",
        title: "cards.tagForFacebookAds",
        icon: "facebook-circle",
      },
      {
        color: "orange",
        title: "cards.tagQtyBins",
        icon: "flash",
      },
    ],
  },
  {
    svg: "google",
    image: "",
    background: "light",
    type: "google",
    title: "Google",
    description: "cards.googleDesc",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
    old_monthly_payment: "",
    qty_bins: "12",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/google",
    hidden: false,
    tags: [
      {
        color: "grey",
        title: "cards.tagForGoogleAds",
        icon: "google-color-circle",
      },
      {
        color: "orange",
        title: "cards.tagQtyBins",
        icon: "flash",
      },
    ],
  },
  {
    svg: "tiktok",
    image: "",
    background: "light",
    type: "tiktok",
    title: "TikTok",
    description: "cards.tiktokDesc",
    transaction_fee: "0 $",
    deposit_fee: "4%",
    monthly_payment: "",
    old_monthly_payment: "",
    qty_bins: "3",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/tiktok",
    hidden: false,
    tags: [
      {
        color: "grey",
        title: "cards.tagForTiktokAds",
        icon: "tiktok-color",
      },
      {
        color: "orange",
        title: "cards.tagQtyBins",
        icon: "flash",
      },
    ],
  },

  {
    svg: "adv",
    image: "",
    background: "light",
    type: "adv",
    title: "Advertisement",
    description: "cards.advDesc",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
    old_monthly_payment: "",
    qty_bins: "15",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/adv",
    hidden: false,
    tags: [
      {
        color: "grey",
        title: "cards.tagForDigitalMediaAds",
        icon: "ads",
      },
      {
        color: "orange",
        title: "cards.tagQtyBins",
        icon: "flash",
      },
    ],
  },

  {
    svg: "platinum",
    image: "",
    background: "light",
    type: "platinum",
    title: "Platinum advertisement",
    description: "cards.platinumDesc",
    transaction_fee: "Special",
    deposit_fee: "6%",
    monthly_payment: "Special",
    old_monthly_payment: "",
    qty_bins: "3",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/platinum",
    hidden: true,
    tags: [
      {
        color: "grey",
        title: "cards.tagForDigitalMediaAds",
        icon: "ads",
      },
      {
        color: "orange",
        title: "cards.tagQtyBins",
        icon: "flash",
      },
    ],
  },

  {
    svg: "black",
    image: "",
    background: "light",
    type: "black",
    title: "PST Black",
    description: "cards.blackDesc",
    transaction_fee: "",
    deposit_fee: "",
    monthly_payment: "",
    old_monthly_payment: "",
    qty_bins: "",
    get_card_link: "",
    get_card_title: "",
    learn_more_link: "",
    hidden: true,
    tags: [
      {
        color: "grey",
        title: "cards.tagForKybUsersOnly",
        icon: "verifed",
      },
      {
        color: "orange",
        title: "cards.tagUniqueBin",
        icon: "flash",
      },
    ],
  },
  {
    svg: "virtual",
    image: "",
    background: "light",
    type: "virtual",
    title: "Virtual",
    description: "cards.virtualDesc",
    transaction_fee: "0 $",
    deposit_fee: "0%",
    withdraw_fee: "0%",
    monthly_payment: "",
    old_monthly_payment: "",
    cost_issue: "7$",
    qty_bins: "",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/virtual",
    hidden: true,
    tags: [
      {
        color: "grey",
        title: "cards.tagForAllPurchases",
        icon: "purchase",
      },
      {
        color: "orange",
        title: "cards.tagTheBestOfOurCards",
        icon: "star",
      },
    ],
  },
  {
    svg: "virtual",
    image: "",
    background: "light",
    type: "payment",
    title: "Payment",
    description: "cards.virtualDesc",
    transaction_fee: "0 $",
    deposit_fee: "0%",
    withdraw_fee: "0%",
    monthly_payment: "",
    old_monthly_payment: "",
    cost_issue: "7$",
    qty_bins: "",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/payment",
    hidden: true,
    tags: [
      {
        color: "grey",
        title: "cards.tagForAllPurchases",
        icon: "purchase",
      },
      {
        color: "orange",
        title: "cards.tagTheBestOfOurCards",
        icon: "star",
      },
    ],
  },
  {
    svg: "virtual",
    image: "",
    background: "light",
    type: "virtualDebit",
    title: "Virtual debit",
    description: "cards.virtualDesc",
    transaction_fee: "0 $",
    deposit_fee: "0%",
    withdraw_fee: "0%",
    monthly_payment: "",
    old_monthly_payment: "",
    cost_issue: "7$",
    qty_bins: "",
    get_card_link: "/",
    get_card_title: "",
    learn_more_link: "/cards/virtual-debit",
    hidden: true,
    tags: [
      {
        color: "grey",
        title: "cards.tagForAllPurchases",
        icon: "purchase",
      },
      {
        color: "orange",
        title: "cards.tagTheBestOfOurCards",
        icon: "star",
      },
    ],
  },
];

export const landingCards: TLandingCard[] = [
  {
    slug: "facebook",
    title: "Facebook",
    background:
      "radial-gradient(125.77% 133.54% at 32.14% 100%, #E8F0FB 0%, #74B0FE 33.33%, #B9CFEB 66.67%, #1877F2 100%)",
    transaction_fee: "0,5 $",
    deposit_fee: "2,9%",
    monthly_payment: "10 $",
  },
  {
    slug: "google",
    title: "Google",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #CEDFFB 0%, #CDEFD6 33.33%, #FBF0CF 66.67%, #F9C8C4 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "tiktok",
    title: "TikTok",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #E7FEFD 0%, #ADE9E7 33.33%, #FFC2CE 66.67%, #FFFFFF 100%)",
    transaction_fee: "0 $",
    deposit_fee: "4%",
  },
  {
    slug: "instagram",
    title: "Instagram",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #E2D590 0%, #F2CBA8 33.33%, #FADAE7 66.67%, #E181DB 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "twitter",
    title: "Twitter",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #E4E4E4 0%, #D1D1D1 33.33%, #E7E7E7 66.67%, #828282 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "microsoft",
    title: "Microsoft",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #FCD5CA 0%, #E6FCB7 33.33%, #B6E5FA 66.67%, #FEE8AE 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "linkedin",
    title: "LinkedIn",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #C2D7E4 0%, #B4D6ED 33.33%, #B8DAEB 66.67%, #64BEEE 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "pinterest",
    title: "Pinterest",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #F5E2E5 0%, #F5AAB5 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "reddit",
    title: "Reddit",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #FBECE4 0%, #F7BC9D 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "quora",
    title: "Quora",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #FBE7E6 0%, #F69D9A 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "taboola",
    title: "Taboola",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #CFDCE4 0%, #D8EBF6 46.35%, #B4E2FC 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "outbrain",
    title: "Outbrain",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #FDF0E8 0%, #E1B69D 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "snapchat",
    title: "Snapchat",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #EEEED2 0%, #F9F779 100%)",
    transaction_fee: "0 $",
    deposit_fee: "6%",
    monthly_payment: "10 $",
  },
  {
    slug: "virtual-prepaid-card",
    title: "Virtual prepaid card",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #EEEED2 0%, #F9F779 100%)",
    transaction_fee: "0 $",
    deposit_fee: "8%",
    monthly_payment: "7 $",
  },
  {
    slug: "payment-card",
    title: "Payment card",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #EEEED2 0%, #F9F779 100%)",
    transaction_fee: "0 $",
    deposit_fee: "8%",
    monthly_payment: "7 $",
  },
  {
    slug: "virtual-debit-card",
    title: "Virtual debit card",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #EEEED2 0%, #F9F779 100%)",
    transaction_fee: "0 $",
    deposit_fee: "8%",
    monthly_payment: "7 $",
  },
  {
    slug: "platinum",
    title: "Platinum card",
    background:
      "radial-gradient(112.74% 119.5% at 32.14% 100%, #EEEED2 0%, #F9F779 100%)",
    transaction_fee: "0 $",
    deposit_fee: "8%",
    monthly_payment: "7 $",
  },
];
